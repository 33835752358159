@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap");

:root {
  /* Grey Base */
  --white: #ffffff;
  --black: #05060E;
  --grey-200: #EFF0F4;
  --grey-400: #DDDEE4;
  --grey-500: #CBCCD6;
  --grey-600: #81869C;
  --grey-800: #565C76;


  --CoolGray-100: "#121619";


  /* Main Colors */
  --dark-blue: #1D3BAF;
  --medium-blue: #465EBD;
  --light-blue: #E8EBF7;
  --yellow: #F9C001;

  /* Secondary Colors */
  --success: #089060;
  --warning: #FAAD14;
  --error: #FF4D4F;


  --Plansom-dark-4:"#05060E"
}
.f-bricolage {
  font-family: "Bricolage Grotesque", serif;
}

.f-inter {
  font-family: "Inter", serif;
}

.fw-600 {
  font-weight: 600;
  line-height: 1.5rem;
}

.blogpost-text {
  font-family: "Inter", serif;
  font-size: 1.125rem;
  color: var(--grey-800);
}

.qasubsec-heading {
  font-family: "Bricolage Grotesque", serif;
  font-size: 1.75rem;
  color: var(--dark);
  line-height: 2.25rem;
  font-weight: 600;
}

.qasubsec-text {
  font-family: "Inter", serif;
  font-size: 1rem;
  color: var(--grey-800);
}

.qasubsec-heading-2 {
  font-family: "Bricolage Grotesque", serif;
  font-size: 1.25rem;
  color: var(--dark);
  line-height: 1.75rem;
  font-weight: 600;
}

.blogcard-heading {
  font-family: "Bricolage Grotesque", serif;
  font-size: 1.5rem;
  color: var(--dark);
  line-height: 2rem;
  font-weight: 600;
}
