.create-goal-button{
    margin-right: 15px !important;
}
.goal-save-button{
    margin-right: 8px !important;
}
.go-to-chat{
    margin-right: 8px !important; 
}
.create-task-button{
    margin-right: 10px !important;
}
.task-go-to-chat{
    margin-right: 8px !important; 
  }
.task-update-button{
    margin-right: 15px !important;
} 
.scroll-container{
    overflow-x: auto;
    white-space: nowrap; 
}
.selected-chat {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
.message-body{
    height: 0px !important;
}
.goal-span{
    margin-left: 10px ;
}
u{
  text-decoration: none;
  font-size: 14px !important;
  
}


@media screen and (min-width: 769px) and (max-width: 1024px) {
    .col-md-6 {
        width: 100% !important;
        }
    .element.style {
        display: inline;
        }
    .choose{
        display: inline;
        margin-top: 22px !important;
        width: 100% !important;
        font-size: medium !important;
    } 
    .transaction-history{
        margin-top: 10px !important;
        width: 100% !important;
        height: 100% !important;
    }
    .current_subscription{
        font-size: 14px !important;
        margin-top: 10px !important;
        width: 100% !important;
        height: 100% !important;
    }
    .new-email-input{
        width: 100% !important;
    }
    .email-verify-button{
        float: left !important;
        font-size: 13px !important;
        margin-top: 10px !important;
        width: 30% !important;
     }
    .org-edit{
        width: 50% !important;
    }
    .otp-card{
        margin-top: 65px !important;
        margin-left:-3px !important;
    }
    .change-email{
        margin-left: -4px !important;
    }
    .task-accordion{
        padding: 8px !important;
    }
    #chatSidebar{
        padding: 0px !important;
        width: 109% !important;
        font-size: 10px !important;
    }
    .plansom-notification{
        min-width: 100% !important;
    }   
  
}

@media screen and (min-width: 450px) and (max-width: 768px) {
    .col-md-6 {
        width: 100% !important;
        }
    .element.style {
        display: inline;
        }
    .choose{
        display: inline;
        margin-top: 22px !important;
        width: 100% !important;
        font-size: medium !important;
    } 
    .transaction-history{
        font-size: 14px !important;
        margin-top: 10px !important;
        width: 300px !important;
        height: 100% !important;
    }
    .shadow-lg{
        width: 100% !important; 
    }
    .current_subscription{
        font-size: 14px !important;
        margin-top: 10px !important;
        width: 300px !important;
        height: 100% !important;
    }
    .profile-data{
        width: 200px !important;
    }
    .profile-details-card{
        width: 100%;
    }
    .profile-details{
        width: 100% !important;
    }
    .new-email-input{
        width: 100% !important;
    }
    .email-verify-button{
       float: left !important;
       font-size: 16px !important;
       margin-top: 10px !important;
       width: 30% !important;
    }
    .org-edit{
        width: 68% !important;
    }
    .profile-edit-button{
        margin-top: -52px !important;
    }
    .otp-card{
        margin-top: 65px !important;
        margin-left:-3px !important;
    }
    .change-email{
        margin-left: -4px !important;
    }
    .create-goal-button{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 50% !important;
        position: relative;
        left: 25% !important;

    }
    .ai-helper{
     padding-left: 15px !important;
    }
    .task-accordion{
        padding: 8px !important;
    } 
    .create-task-button{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 50% !important;
        position: relative;
        left: 25% !important;
        margin-bottom: 20px !important; 
    } 
    .task-go-to-chat {
        margin-right: 16px !important;
    }
    .chat-body {
        width: 70% !important;
        margin-left: 27px !important;
    } 
    .chat-row{
        padding: 0px !important;
    }
    .topic-name{
        font-size: 14px !important;
    }
    #chatSidebar{
        padding: 0px !important;
        width: 125% !important;
        font-size: 10px !important;
    }
    .plansom-notification{
        min-width: 100% !important;
    } 
    .org-name.flex-wrap{
        font-size: 16px !important;
    }
    u{
        font-size: 13px !important;
    }
    

} 
@media screen and (min-width: 376px) and (max-width: 449px) {
    .choose {
        font-size:small;
        width: 300px !important;
        height: 50px !important;
        }
    .transaction-history{
        font-size:13px !important;
        width: 170px !important;
        height: 100% !important;
    }
    .current_subscription{
        font-size:13px !important;
        width: 170px !important;
        height: 100% !important;
    }
    .profile-data{
        width: 87% !important;
    }
    .profile-details{
        width: 100% !important;
    }
    .profile-details-card{
        width: 100%;
    }
    .shadow-lg{
        width: 100% !important; 
    }
    .profile-edit-button{
        margin-top: -45px !important;
        margin-bottom: 0px !important;
    }
    .new-email-input{
        width: 100% !important;
    }
    .email-verify-button{
        float: left !important;
        font-size: 13px !important;
        margin-top: 10px !important;
        width: 30% !important;
     }
     .list-unstyled {
        margin-left: 2px !important;
        margin-right: 2px !important; 
      }
      .otp-card{
        margin-top: 65px !important;
        margin-left:-3px !important;
    }   
    .change-email{
        margin-left: -4px !important;
    }
    .create-goal-modal{
        padding: 10px !important; 
     }
    .create-goal-button{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 50% !important;
        top: -25px !important;
        position: relative;
        left: 25% !important;

    }
    .header-nav{
        padding: 0px !important;
    }
    .ai-helper{
        padding: 5% !important;
    }
    .goal-save-button{
        width: 92% !important;
        }
    .go-to-chat{
        width: 92% !important; 
        margin-bottom: 10px !important;
        } 
    .task-accordion{
        padding: 8px !important;
    }
    .create-task-button{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 50% !important;
        position: relative;
        left: 25% !important;
        margin-bottom: 20px !important; 
    }  
    .task-update-button{
      width: 92% !important;
      margin-left: 4% !important;
    }
    .task-go-to-chat{
      width: 96% !important;
      margin-left: 2% !important;
    } 

    .dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper
    .dx-popup-content
    .dx-list-item,
  .dx-scheduler-overlay-panel .dx-popup-content .dx-list-item{
          width: 25rem;
        }
    .calender_filter{
        padding: 0px !important;
    }

    .thread-name{
        font-size: 18px !important;
    }
    .message-row {
        margin-left: 0px !important;
        margin-top: 5px !important;
    }
    #chatSidebar{
        padding: 0px !important;
    }  
    .chat-slider{
        display: none;
    }
    .chat-row{
        padding: 0px !important;
        margin-top: 5px !important;
    }
    .plansom-notification{
        min-width: 100% !important;
        font-size: 14px !important;
    }
    #hidescrollbar{
        margin-top: -35px !important;
    } 
    .org-name.flex-wrap{
        font-size: 16px !important;
    }
    .group-list{
        margin: 1px !important;
    } 
    .media.justify-content-right.align-items-start {
        margin-left: -24px !important;
    }
    .org-name-filter{
        max-width: 80px !important;
    }
    u{
        font-size: 13px !important;
    }
    .user-performace{
        font-size: 18px !important;
        margin-top: 10px !important;
        margin-right: 10px !important;
    }
    .user-performance-row{
        padding: 0px !important;
    }
    .container-fluid{
        margin-top: 10px !important;
    }
    .custom-h4 {
        font-size: 12px;
      }
   
   
    
}

@media screen and (min-width: 321px) and (max-width: 375px) {
    .choose {
    font-size:small;
    width: 270px !important;
    height: 50px !important;
    }
    
    .transaction-history{
        font-size:13px !important;
        width: 150px !important;
        height: 100% !important;
    }
    .current_subscription{
        font-size:13px !important;
        width: 150px !important;
        height: 100% !important;
    }
    .profile-details{
        width: 100% !important;
    }
    .profile-data{
        width: 100% !important;
    }
    .info-name{
        font-size: 20px !important;
        margin: 17px;
    }
    .profile-details-card{
        width: 100%;
    }
    .shadow-lg{
        width: 100% !important; 
    }
    .profile-edit-button{
        margin-top: -45px !important;
        margin-bottom: 0px !important;
    }
    .new-email-input{
        width: 100% !important;
    }
    .email-verify-button{
        float: left !important;
        font-size: 12px !important;
        margin-top: 10px !important;
        width: 40% !important;
     }
     .list-unstyled {
        margin-left: 2px !important; 
        margin-right: 2px !important;
      }
      .otp-card{
        margin-top: 65px !important;
        margin-left:-3px !important;
    } 
    .change-email{
        margin-left: -4px !important;
    }
    .create-goal-modal{
        padding: 10px !important; 
     }
    .create-goal-button{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 50% !important;
        top: -25px !important;
        position: relative;
        left: 25% !important;
    }
    .ai-helper{
        padding: 5% !important;
    }
    .goal-save-button{
        width: 90% !important;
        }
    .go-to-chat{
        width: 90% !important; 
        margin-bottom: 10px !important;
        }
    .task-accordion{
        padding: 8px !important;
    }
    .create-task-col{
        width: 95% !important;
        margin-left: 8px !important;
    }
    .header-nav{
        padding: 0px !important;
    }
    .create-task-button{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 50% !important;
        position: relative;
        top:-20px !important;
        left: 25% !important;
        margin-bottom: 20px !important; 
    }
    .task-update-button{
        width: 91% !important;
        margin-left: 4% !important;
      }
    .task-go-to-chat{
        width: 95% !important;
        margin-left: 2% !important;
    }
    .search-attach-name{
        font-size: 10px !important;
    }
    .dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper
    .dx-popup-content
    .dx-list-item,
  .dx-scheduler-overlay-panel .dx-popup-content .dx-list-item{
          width: 20rem;
        }
    .dx-scheduler-header-panel-cell{
        font-size: smaller;
    }
    .dx-button-content{
        width: 30px !important;
    }
    .calender_filter{
        padding: 0px !important;
    } 
    .thread-name{
        font-size: 16px !important;
    }
    .message-row {
        margin-left: 0px !important;
        margin-top: 5px !important;
    }
    #chatSidebar{
        padding: 0px !important;
    }
    .chat-slider{
        display: none;
    }

    .chat-row{
        padding: 0px !important;
        margin-top: 5px !important;
    } 
    .plansom-notification{
        min-width: 100% !important;
        font-size: 14px !important;
    } 
    #hidescrollbar{
        margin-top: -35px !important;
    }
    .org-name.flex-wrap{
        font-size: 14px !important;
    }
    .group-list{
        margin: 1px !important;
    }
    .media.justify-content-right.align-items-start {
        margin-left: -24px !important;
    }
    .m-0.my-3.p-0.row {
        height: 412px !important;
    }
    .loader{
        padding: 0px !important;
        /* margin: 116px !important; */
    }
    .org-name-filter{
        max-width: 70px !important;
    }
    u{
        font-size: 11px !important;
    }
    .user-performace{
        font-size: 18px !important;
        margin-top: 10px !important;
    }
    .user-performance-row{
        padding: 0px !important;
    }
    .container-fluid{
        margin-top: 10px !important;
    }
    .custom-h4 {
        font-size: 12px;
    }
    .create-sub-team-button{
        width: 49%;
    } 
    .show-sub-team-button{
        width: 49%;
    }  
    
   
  
 
}
@media screen and (max-width: 320px) {
    .choose {
        font-size:small;
        width: 220px !important;
        height: 50px !important;
        }
    .transaction-history{
        font-size:10px;
        width: 150px !important;
        height: 100% !important;
    }
    .current_subscription{
        font-size:10px;
        width: 150px !important;
        height: 100% !important;
    }

    .profile-details{
        width: 100% !important;
    }
    .profile-data{
        width: 100% !important;
    }
    .info-name{
        font-size: 14px !important;
    }
    .profile-details-card{
        width: 100%;
    }
    .shadow-lg{
        width: 100% !important; 
    }
    .profile-edit-button{
        margin-top: -45px !important;
        margin-bottom: 0px !important;
    }
    .new-email-input{
        width: 100% !important;
    }
    .email-verify-button{
       float: left !important;
       font-size: 12px !important;
       margin-top: 10px !important;
       width: 42% !important;
    }
    .list-unstyled {
        margin-left: 2px !important; 
        margin-right: 2px !important; 
      }
    .otp-card{
        margin-top: 65px !important;
        margin-left:-3px !important;
    } 

    .change-email{
        margin-left: -4px !important;
    }
    .create-goal-modal{
       padding: 10px !important; 
    }
    .create-goal-button{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 50% !important;
        top: -25px !important;
        position: relative;
        left: 25% !important;
    }
    .ai-helper{
        padding: 5% !important;
    }
    .goal-save-button{
    width: 89% !important;
    }
    .go-to-chat{
    width: 89% !important; 
    margin-bottom: 10px !important;
    }
    .create-task-modal{
        padding: 10px !important; 
    }
    .create-task-col{
        width: 95% !important;
        margin-left: 8px !important;
    }
    .task-accordion{
        padding: 8px !important;
    }
    .create-task-button{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 50% !important;
        position: relative;
        top: -25px !important;
        left: 25% !important;
        margin-bottom: 20px !important; 
    }
    .update-task-modal{
        padding: 0px !important;
    }
    .task-update-button{
      width: 90% !important;
      margin-left: 5% !important;
    }
    .task-go-to-chat{
      width: 94% !important;
      margin-left: 3% !important;
    }
    .chat-img1 img {
      margin-left: 10px !important;
      margin-top: -30px !important;
    }
    .time-card-body{
        padding: 0px;
        margin-left: -11px !important;
    }
    .header-nav{
        padding: 0px !important;
    }
    .sidebar-icon{
        padding: 0px !important;
    } 
    .search-attach-name{
        font-size: 8px !important;
    }
    .body-scrollbar{
        height: 300px !important;
    }
    .dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper
  .dx-popup-content
  .dx-list-item,
.dx-scheduler-overlay-panel .dx-popup-content .dx-list-item{
        width: 18rem;
      }
    .dx-scheduler-header-panel-cell{
        font-size: smaller;
    }
    .dx-button-content{
        width: 25px !important;
    }
    .dx-widget.dx-button.dx-button-mode-contained.dx-button-default.dx-scheduler-appointment-collector{
        width: 15px !important;
    }
    .calender_filter{
        padding: 0px !important;
    }
    .thread-name{
        font-size: 12px !important;
    }
    .message-row {
        margin-left: 0px !important;
        margin-top: 5px !important;
    }
    #chatSidebar{
        padding: 0px !important;
    }
    .chat-row{
        padding: 0px !important;
        margin-top: 5px !important;
    }
    .chat-slider{
        display: none;
    }  
    #chartBox{
        margin-left: -4px !important;
    }    
    .loader{
        padding: 0px !important;
        /* margin: 95px !important; */
    }
    .plansom-notification{
        min-width: 100% !important;
        font-size: 12px !important;
    } 
    #hidescrollbar{
        margin-top: -35px !important;
    }
    .org-name.flex-wrap{
        font-size: 12px !important;
    }
    .group-list{
        margin: 1px !important;
    }
    .media.justify-content-right.align-items-start {
        margin-left: -24px !important;
    }
    .org-name-filter{
        max-width: 60px !important;
    }
    u{
        font-size: 10px !important;
    }
    .user-performace{
        font-size: 14px !important;
        margin-top: 10px !important;
    }
    .user-performance-row{
        padding: 0px !important;
    }
    .container-fluid{
        margin-top: 10px !important;
    }
    .custom-h4 {
        font-size: 10px;
      }
    .create-sub-team-button{
        width: 49%;
    } 
    .show-sub-team-button{
        width: 49%;
    }

   
      
    
}


@media (max-width: 450px) {
    .profile-col-body {
      width: 100%;
    }
  }
@media (min-width: 450px) {
   .profile-col-body{
    flex: 0 0 50%;
    max-width: 50%;
}
.search-dropdown{
    display: none;
}
} 

@media (max-width: 450px) {
    /* .attachment-drag {
      display: none;
    } */
    .headerdropdown{
        display: none;
    }
    .react-search-box{
        display: none;
    }
    .file-import-tooltip{
        display: none;
    }
    .header{
        height: 100px !important;
    }
    .content-body{
        margin-top: 20px !important;
    }
    
  }





