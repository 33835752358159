
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.paragraph-style {
    color: var(--Plansom-dark-3, #565C76);
    font-family: "Inter", serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 133.333% */

  }


  .aboutus-paragraph-style {
    color: var(--Plansom-dark-3, #565C76);
    font-family: "Inter", serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 133.333% */

  }

  .mission-paragraph-style{
    color: var(--Plansom-dark-3, #565C76);
    font-family: "Inter", serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem; /* 140% */
  }

  .fw-600{
    font-weight: 600;
    line-height: 1.5rem;
    font-family: "Inter", serif;
    
}


/* @media (min-width: 2000px) {
  html {
    font-size: 28px;
  }
} 


@media (min-width: 770px) and (max-width: 1024px) {
  html {
    font-size: 14px;
  }
} */