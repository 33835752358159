/* path/to/PricingPage.css */
.main-layout {
  background: #1849c6;
  padding: 40px 60px;
}

.custom-login-button > span:not(:only-child) {
  display: inline-flex;
  align-self: center !important; /* Ensure it overrides baseline */
}

.custom-input::placeholder {
  color: #9eabdd;
}

.pricing-container {
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  padding: 80px 50px;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.title-section {
  color: #ffffff;
  font-size: 38px;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.2;
}

.gold-text {
  color: #ffd042;
}

.toggle-section {
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
}

.period-switch {
  background: rgba(255, 255, 255, 0.2) !important;
}

.active-period {
  font-weight: 500;
  font-family: "Inter";
  font-size: 16px;
}

.cards-container {
  margin-top: 30px;
}

.pricing-card {
  background: #ffffff30 !important;
  border-radius: 16px !important;
  border: none !important;
  padding: 24px !important;
}

.card-header {
  color: #ffffff;
  font-size: 24px;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  margin-bottom: 12px;
  border-bottom: none;
}

.card-header2 {
  color: #05060e;
  font-size: 24px;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  border-bottom: none;
}

.card-subtitle {
  color: #f7f8fc;
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter";
  margin-bottom: 24px;
}

.card-subtitle2 {
  color: #565c76;
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter";
}

.price-section {
  color: white;
  margin: 24px 0;
}

.currency {
  font-size: 38px;
  vertical-align: top;
  margin-right: 4px;
  font-weight: 600;
  color: "#FFFFFF";
}

.amount {
  font-size: 38px;
  font-weight: 600;
  color: "#FFFFFF";
}

.currency2 {
  font-size: 38px;
  vertical-align: top;
  margin-right: 4px;
  font-weight: 600;
  /* color: "#05060E"; */
}

.amount2 {
  font-size: 38px;
  font-weight: 600;
  color: "#05060E";
}

.period {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 4px;
}

.features {
  margin: 24px 0;
}

.feature-item {
  color: white;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.check-icon {
  color: rgba(255, 255, 255, 0.6);
}

.select-button {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  font-weight: 500;
  border: none !important;
}

.pricing-card {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 16px !important;
  border: none !important;
  padding: 24px !important;
  height: 759px !important; /* Fixed height as per requirement */
  display: flex !important;
  flex-direction: column !important;
}

.card-content {
  flex: 1; /* Takes up available space */
}

.button-container {
  margin-top: auto; /* Pushes button to bottom */
  padding-top: 24px;
}

.select-button {
  width: 100%;
  height: 48px;
  border-radius: 30px;
  font-weight: 500;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.starter-button {
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter";
  background: white !important;
  color: #05060e !important;
}

.pro-button {
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter";
  background: #1d3baf !important;
  color: white !important;
}

/* Hover states */
.select-button:hover {
  opacity: 0.9;
}

/* Responsive */
@media (max-width: 768px) {
  .onboarding-header {
    height: 10vh !important;
  }

  .onboarding-subtitle {
    font-size: 16px !important;
    font-weight: 600;
    font-family: "Bricolage Grotesque", sans-serif;
    color: #0d1b4f;
    margin-bottom: 0px;
  }

  .onboarding-chatui {
    max-width: 80% !important;
  }

  .onboarding-chatIcon {
    display: none !important;
  }

  .onboarding-userIcon {
    display: none !important;
  }

  .main-layout {
    padding: 20px;
  }

  .header-section {
    gap: 20px;
    margin-bottom: 20px;
  }

  .cards-container .ant-col {
    width: 100%;
    margin-bottom: 20px;
  }
  .top-margin {
    margin-top: 20px !important;
  }
}

.cards-container {
  margin-top: 30px;
}

.pricing-card {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 16px !important;
  border: none !important;
  padding: 24px !important;
  height: 100% !important; /* Add fixed height */
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.features {
  margin: 24px 0;
  flex-grow: 1; /* Allow features section to take up remaining space */
}

.price-section {
  color: white;
  margin: 24px 0;
  min-height: 80px; /* Add minimum height for price section */
  display: flex;
  align-items: center;
}

.pricing-card {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 16px !important;
  border: none !important;
  padding: 24px !important;
}

/* Add this new class for the pro card */
.pricing-card.pro-card {
  background: rgba(255, 255, 255, 1) !important; /* Solid white background */
}

/* Update text colors for pro card to maintain readability */
.pro-card .card-header,
.pro-card .card-subtitle,
.pro-card .price-section,
.pro-card .feature-item,
.pro-card .check-icon {
  color: #1849c6 !important; /* Use the blue color for text */
}

.faq-section {
  /* max-width: 1440px; */
  /* margin: 0 auto; */
  padding: 120px 60px;
  background: #f8f9ff;
}

.faq-title {
  font-family: "Bricolage Grotesque";
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 48px;
}

.faq-title span {
  color: #1e2875;
}

.ant-collapse {
  background: transparent !important;
}

.ant-collapse > .ant-collapse-item {
  margin-bottom: 16px;
  border: none !important;
  border-radius: 12px !important;
  overflow: hidden;
}

.ant-collapse-header {
  padding: 24px 32px !important;
  background: white !important;
}

.ant-collapse-header-text {
  font-family: "Open Sans", sans-serif;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #000000 !important;
}

.ant-collapse-content {
  background: white !important;
}

.ant-collapse-content-box {
  padding: 0 32px 24px !important;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.7);
}

.ant-collapse-expand-icon {
  color: #1e2875 !important;
}

/* Custom styles for the slider */
.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide > div {
  height: 100%;
}

/* Optional: Add custom arrows styles */
.slick-prev:before,
.slick-next:before {
  display: none;
}

/* Ensure cards have equal height */
.testimonial-card {
  height: 100%;
  margin: 10px;
}

/* Add smooth transition for hover effect */
.testimonial-card {
  transition: all 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

@media (min-width: 320px) and (max-width: 420px) {
  .onboarding-header {
    height: 10vh !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .onboarding-messages-section {
    height: calc(100vh - 120px) !important;
  }

  .onboarding-optionbuttons {
    padding: 35px 10px !important;
  }

  .onboarding-subtitle {
    font-size: 12px !important;
    font-weight: 600;
    font-family: "Bricolage Grotesque", sans-serif;
    color: #0d1b4f;
    margin-bottom: 0px;
  }

  .onboarding-chatui {
    max-width: 100% !important;
  }

  .onboarding-chatIcon {
    display: none !important;
  }

  .onboarding-userIcon {
    display: none !important;
  }

  .onboarding-plansomlogo {
    width: 100px !important;
    height: 100px !important;
  }
}

@media (min-width: 421px) and (max-width: 520px) {
  .onboarding-header {
    height: 10vh !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .onboarding-optionbuttons {
    padding: 35px 10px !important;
  }

  .onboarding-subtitle {
    font-size: 16px !important;
    font-weight: 600;
    font-family: "Bricolage Grotesque", sans-serif;
    color: #0d1b4f;
    margin-bottom: 0px;
  }

  .onboarding-messages-section {
    height: calc(100vh - 120px) !important;
  }

  .onboarding-chatui {
    max-width: 90% !important;
  }

  .onboarding-chatIcon {
    display: none !important;
  }

  .onboarding-userIcon {
    display: none !important;
  }
}

@media (min-width: 521px) and (max-width: 600px) {
  .onboarding-header {
    height: 10vh !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .onboarding-optionbuttons {
    padding: 25px 10px !important;
  }

  .onboarding-subtitle {
    font-size: 18px !important;
    font-weight: 600;
    font-family: "Bricolage Grotesque", sans-serif;
    color: #0d1b4f;
    margin-bottom: 0px;
  }

  .onboarding-messages-section {
    height: calc(100vh - 120px) !important;
  }

  .onboarding-chatui {
    max-width: 90% !important;
  }

  .onboarding-chatIcon {
    display: none !important;
  }

  .onboarding-userIcon {
    display: none !important;
  }
}

@media (min-width: 601px) and (max-width: 767px) {
  .onboarding-header {
    height: 10vh !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .onboarding-subtitle {
    font-size: 20px !important;
    font-weight: 600;
    font-family: "Bricolage Grotesque", sans-serif;
    color: #0d1b4f;
    margin-bottom: 0px;
  }

  .onboarding-messages-section {
    height: calc(100vh - 120px) !important;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .onboarding-header {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .onboarding-chatui {
    max-width: 75% !important;
  }
}

@media (min-width: 991px) and (max-width: 1250px) {
  .onboarding-chatui {
    max-width: 75% !important;
  }
}

@media (min-width: 360px) and (max-width: 700px) {
  .header-container {
    padding: 10px 0px !important;
  }
  .plansom-logo {
    width: 124px !important;
  }
  .landing-title {
    font-size: 40px !important;
  }
  .landing-layout-content {
    padding: 10px 26px !important;
  }
  .landing-main-layout {
    padding: 0px 0px !important;
  }
  .Succes-stories {
    padding: 0px 0px !important;
  }
  .landing-inner-content {
    padding: 50px 0px !important;
  }
  .sub-heading-title {
    font-size: 28px !important;
  }
  .content-font-size {
    font-size: 16px !important;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .landing-title {
    font-size: 50px !important;
  }
}

@media (min-width: 900px) and (max-width: 1145px) {
  .landing-title {
    font-size: 65px !important;
  }
}

@media (min-width: 1390px) and (max-width: 1563px) {
  .plansom-card-div {
    margin-top: 24% !important;
  }
}

@media (max-width: 768px) {
  .testimonial-card {
    max-width: 90%;
    /* Card takes most of the screen width on mobile */
    min-height: 180px;
  }
}

.react-grid-HeaderCell-sortable {
  cursor: none !important;
  pointer-events: none !important;
}

.videoPlayButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  color: white;
  cursor: pointer;
  z-index: 3;
}

.imageCardStyle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #05060e99;
  z-index: 2;
}

.containers {
  position: relative;
}

.content {
  position: relative;
  z-index: 2; /* Bring content to the front */
  text-align: center;
  background: white;
  /* padding: 2rem; */
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.containers::before,
.containers::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30%;
  background: linear-gradient(
    to right,
    rgba(247, 249, 252, 1),
    rgba(108, 155, 229, 0)
  );
  z-index: 1; /* Place behind the content */
  pointer-events: none; /* Prevent pseudo-elements from capturing clicks */
}

.containers::before {
  left: 0;
}

.containers::after {
  top: 0;
  bottom: 0;
  width: 80%;
  background: linear-gradient(
    to left,
    rgba(247, 249, 252, 1),
    rgba(247, 249, 252, 0)
  );
}

.stars {
  display: flex;
  gap: 10px;
}

.star {
  font-size: 10px;
  opacity: 0.5;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.star.highlighted {
  opacity: 1;
  transform: scale(1.5); /* Increase size slightly when highlighted */
}

.heart-svg {
  margin-left: -10px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  animation: beat 1s infinite;
  transform-origin: center;
}

@keyframes beat {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.button-confirmed {
  position: relative;
  width: 127px;
  height: 56px;
  background-color: #083a65;
  border: 0;
  color: white;
  font-size: 18px;
  border-radius: 40px;
  cursor: pointer;
  max-width: 100%;
}

.button-confirmed svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.button-confirmed rect {
  animation: button-border 3s linear both infinite;
}

@keyframes button-border {
  to {
    stroke-dashoffset: 0;
  }
}

.confirm-text {
  font-weight: 600;
  font-size: 16px;
  font-family: Inter;
  margin-left: -11px;
}

.fw-600 {
  font-weight: 600;
}
