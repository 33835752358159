@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700");
@import url("./../icons/simple-line-icons/css/simple-line-icons.css");
@import url("./../icons/font-awesome/css/all.min.css");
@import url("./../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("./../icons/themify-icons/css/themify-icons.css");
@import url("./../icons/line-awesome/css/line-awesome.min.css");
@import url("./../icons/avasta/css/style.css");
@import url("./../icons/flaticon/flaticon.css");
@import url("./../icons/flaticon_1/flaticon_1.css");
@import url("./../icons/icomoon/icomoon.css");
@import url("./../icons/bootstrap-icons/font/bootstrap-icons.css");
@import url(./../vendor/animate/animate.min.css);
@import url(./../vendor/aos/css/aos.min.css);
@import url(./../vendor/perfect-scrollbar/css/perfect-scrollbar.css);
@import url(./../vendor/metismenu/css/metisMenu.min.css);


.home-images-formatting {
  height: auto !important;
}
.aboutuspage-image-position1 {
  margin-top: 11rem !important;
}

.aboutuspage-image-position2 {
  margin-top: 6rem !important;
  position: relative;
}

.aboutuspage-image-position3 {
  margin-top: 10rem !important;
}

.aboutuspage-image-position4 {
  margin-top: 5rem !important;
}

.figma-layout {
  display: flex;
  width: 632px;
  padding: 8px 8px 8px 16px;
  align-items: flex-start;
  gap: 16px;
  background-color: "red";
}
.headers {
  padding: 2% 10%;
  display: inline-block;
}

.hitext {
  font-size: 18px !important;
}

.plansom-logo {
   /* height: 4vh; */
  margin-right: 6%; 
  /* margin-left: -2.5%; */
  /* object-fit: contain;
  vertical-align: top; */
  /* width: 22vh; */
  /* display: flex; */
}

.header-item {
  align-items: center;
  column-gap: calc(100% - 80%);
  display: flex;
  margin-right: 38%;
}
.stratergy {
  padding: 10rem 0rem 0rem 5rem;
}
.align-tasks-with-strategic-goals-MH5 {
  font-family: Libre Baskerville, "Source Sans Pro";
}
.Group-2 {
  height: 65vh;
  width: 100%;
  margin-top: 5vh;
}
.group3 {
  height: 27vh;
  width: 100%;
  /* margin-top: 5vh; */
}
.group5 {
  height: 50vh;
  width: 50vw;
}
.items {
  color: #131f4c;
  flex-shrink: 0;
  font-family: Open Sans, "Source Sans Pro";
  font-size: 2.2vh;
  font-weight: 400;
  line-height: 1.2000000477;
  white-space: nowrap;
}

.header-buttons {
  align-items: center;
  display: flex;
  padding-top: 10px;
  column-gap: calc(100% - 85%);
}

.try-button {
  align-items: center;
  background-color: #346fd3;
  border-radius: 0.4rem;
  color: #ffffff;
  display: flex;
  flex-shrink: 0;
  font-family: Open Sans, "Source Sans Pro";
  font-size: 2.4vh;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  line-height: 1.3625;
  white-space: nowrap;
  width: 6.5vw;
}
.try-button1 {
  font-family: Open Sans, "Source Sans Pro";
  text-align: center;
  background-color: #346fd3;
  border-radius: 0.4rem;
  color: #ffffff;
  justify-content: center;
  padding: 9px 9px;
  font-size: 2.4vh;
}

.quote {
  display: flex;
  flex-direction: column;
  /* padding-left: 9vw;
  padding-top: 10rem;
  width: 58vw; */
}

.quote1 {
  color: #131f4c;
  flex-shrink: 0;
  font-family: Libre Baskerville, "Source Sans Pro";
  font-size: 6%;
  font-weight: 700;
  line-height: 1.0800000359;
  margin-bottom: 4.8vh;
}

.quote1-sub-0 {
  color: #131f4c;
  font-family: Libre Baskerville, "Source Sans Pro";
  font-size: 10vh;
  font-weight: 700;
  line-height: 1.0800000359;
}

.quote1-sub-1 {
  color: #4dbfdb;
  font-family: Libre Baskerville, "Source Sans Pro";
  font-size: 6vh;
  font-weight: 700;
  line-height: 1.0800000359;
}

.quote-text {
  color: #131f4c;
  font-family: Open Sans, "Source Sans Pro";
  font-size: 2.2vh;
  font-weight: 500;
  line-height: 1.6000000636;
  white-space: nowrap;
}

.free-trail {
  color: rgba(19, 31, 76, 0.5);
  flex-shrink: 0;
  font-family: Open Sans, "Source Sans Pro";
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6000000238;
  margin: 0.6vh 18.2vw 0vh 2vw;
  white-space: nowrap;
}
.stop-useless {
  color: #346fd3;
  flex-shrink: 0;
  font-family: Open Sans, "Source Sans Pro";
  font-size: 1.5vmax;
  font-weight: 500;
  letter-spacing: 0.16rem;
  line-height: 1.2000000477;
  margin-bottom: 1.9vmax;
  text-transform: uppercase;
  white-space: nowrap;
}

.focus {
  color: #131f4c;
  flex-shrink: 0;
  font-family: Libre Baskerville, "Source Sans Pro";
  font-size: 3.2vmax;
  font-weight: 700;
  line-height: 1.0800000241;
  margin-bottom: 1.2vmax;
  white-space: nowrap;
}

.focus-1 {
  color: #131f4c;
  flex-shrink: 0;
  font-family: Libre Baskerville, "Source Sans Pro";
  font-size: 3.6vmax;
  font-weight: 700;
  line-height: 1.0800000241;
  margin-bottom: 1.2vmax;
  white-space: nowrap;
}

.vector-13 {
  height: 16vmax;
  left: 8vmax;
  object-fit: contain;
  position: absolute;
  top: 0rem;
  vertical-align: top;
  width: 20vmax;
}

.vector-14 {
  flex-shrink: 0;
  height: 3vmax;
  /* left: 25vmax; */
  margin-bottom: 0.1rem;
  object-fit: contain;
  vertical-align: top;
  width: 60vmax;
  background: none;
}

.design-concept-6hZ {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.auto-group-krrj-u9D {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 2rem 8rem 4rem 8rem;
  width: 100%;
}
.navbar-hUP {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 8vh;
  margin: 0vh 20vw 4vh 0vw;
  width: calc(100% - 16rem);
}
.left-xv7 {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: calc(100% - 1.8zrem);
  margin: 0vh 32vw 2vh 0vw;
}
.primary-logo-Trs {
  flex-shrink: 0;
  height: 2.4043rem;
  margin-right: 4rem;
  object-fit: contain;
  vertical-align: top;
  width: 13.2rem;
}

.menu-LQs {
  align-items: center;
  column-gap: 4rem;
  display: flex;
  flex-shrink: 0;
  height: calc(100% - 0.4rem);
  margin: 5% 0% 0.15% 0%;
}

.about-us-paw {
  color: #131f4c;
  flex-shrink: 0;
  font-family: Open Sans, "Source Sans Pro";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2000000477;
  white-space: nowrap;
}
.blog-cQK {
  color: #131f4c;
  flex-shrink: 0;
  font-family: Open Sans, "Source Sans Pro";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2000000477;
  white-space: nowrap;
}

.pricing-6io {
  color: #131f4c;
  flex-shrink: 0;
  font-family: Open Sans, "Source Sans Pro";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2000000477;
  white-space: nowrap;
}

.buttons-zJP {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  column-gap: 3rem;
  height: calc(100% - 0.4rem);
}

.log-in-7P1 {
  color: #131f4c;
  flex-shrink: 0;
  font-family: Open Sans, "Source Sans Pro";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2000000477;
  margin: 0% 4% 0% 0%;
  white-space: nowrap;
}

.buttonn-yg7 {
  align-items: center;
  background-color: #346fd3;
  border-radius: 0.4rem;
  color: #ffffff;
  display: flex;
  flex-shrink: 0;
  font-family: Open Sans, "Source Sans Pro";
  font-size: 1.6rem;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  line-height: 1.3625;
  white-space: nowrap;
  width: 50%;
}

.auto-group-ffmu-bSb {
  align-items: flex-end;
  display: flex;
  flex-shrink: 0;
  /* margin-bottom: 1rem; */
  width: 100%;
}

.auto-group-th8p-gU3 {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 5%;
  width: 42vw;
}

.the-tool-you-need-to-inspire-your-team-QQ3 {
  color: #131f4c;
  flex-shrink: 0;
  font-family: Libre Baskerville, "Source Sans Pro";
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.0800000359;
  margin-bottom: 2.8%;
  min-width: 35rem;
}

.the-tool-you-need-to-inspire-your-team-QQ3-sub-0 {
  color: #131f4c;
  font-family: Libre Baskerville, "Source Sans Pro";
  font-size: 6.8rem;
  font-weight: 700;
  line-height: 1.0800000359;
}
.the-tool-you-need-to-inspire-your-team-QQ3-sub-1 {
  color: #4dbfdb;
  font-family: Libre Baskerville, "Source Sans Pro";
  font-size: 6.8rem;
  font-weight: 700;
  line-height: 1.0800000359;
}

.align-around-strategic-goals-track-and-recognize-achievement-kPM {
  color: #131f4c;
  flex-shrink: 0;
  font-family: Open Sans, "Source Sans Pro";
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.6000000636;
  white-space: nowrap;
}

.auto-group-dphd-pe7 {
  flex-shrink: 0;
  height: 43rem;
  /* margin-bottom: 0.8rem; */
  position: relative;
  width: 23vw;
}
.vector-ijV {
  height: 74vh;
  left: 5vw;
  object-fit: contain;
  position: absolute;
  top: 0;
  vertical-align: top;
  width: 16vw;
}
.widget-pnX {
  /* align-items: center; */
  /* background-color: #ffffff; */
  /* border-radius: 0.8rem; */
  /* box-shadow: 0 0.8rem 1.6rem rgba(19, 31, 76, 0.1599999964); */
  /* box-sizing: border-box; */
  /* display: flex; */
  height: 10.5rem;
  left: -7vw;
  padding: 0rem;
  position: absolute;
  top: 60%;
  width: 26vw;
}

.bgWhite {
  background-color: #f5f5f5;
}

.bgGray {
  background-color: #dce6f5;
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 160px;
  gap: 93px;
  position: relative;
  width: 1440px;
  height: 78px;
  left: 0px;
  top: 0px;
}

.p-logos {
  height: 60px;
  margin-right: 6vmax;
  object-fit: contain;
  vertical-align: top;
  width: 15rem;
  display: flex;
}

.headeritems {
  color: #131f4c;
  flex-shrink: 0;
  font-family: Open Sans, "Source Sans Pro";
  font-size: 2.2vh;
  font-weight: 400;
  margin-right: 3vmax;
  padding-top: 3.3vh;
  line-height: 0.2000000477;
  white-space: nowrap;
}

.trybutton {
  /* padding-top: 3.3vh; */
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-shrink: 0;
  font-family: Open Sans, "Source Sans Pro";
  font-size: 2.4vh;
  font-weight: 400;
  width: 94px;
  height: 38px;
  background: #356fd4;
  border-radius: 4px;
  justify-content: center;
  line-height: 1.3625;
  white-space: nowrap;
}

.image-size {
  height: 25vh;
  width: 48vw;
}
.section-heading {
  font-size: 2vh;
  font-weight: 600;
}

.col-width {
  width: 4vw;
}

.section-title {
  color: rgb(53, 111, 212);
  font-size: 1.6vw;
}

.section-info {
  font-size: 1.8vw;
}

/* @media screen and (min-width: 500px) and (max-width: 576px) {
  .col-width {
    width: 3vw;
  }
} */

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .textForSize{
    margin-bottom: 58px;
  }
  .textsizeline{
    margin-bottom: 20px;
  }
  .textHeadlineheight{
    margin-bottom: 50px;
  }
  .glBtLk {
    height: 33px !important;
  }


  .btn-small {
    padding: 0.625rem 3rem 0.625rem 0.8rem !important;
    font-size: 0.7rem !important;
  }

  .header-buttonforSmall {
    padding-top: 0px !important;
  }

  .image-size {
    height: 30vh !important;
    width: 43vw !important;
  }

  .image-size4 {
    height: 30vh !important;
    width: 43vw !important;
  }

  .plansompage-image1 {
    margin-top: 6rem !important;
  }

  .plansompage-image2 {
    margin-top: 7.5rem !important;
  }

  .plansompage-image3 {
    margin-top: 7rem !important;
  }

  .plansompage-image4 {
    margin-top: 2rem !important;
  }

  .hitext {
    font-size: 12px !important;
  }

  .plansompage-image5 {
    margin-top: 5rem !important;
  }

  .plansompage-image6 {
    margin-top: 4rem !important;
  }

  .plansompage-image7 {
    margin-top: 2rem !important;
  }

  .plansompage-image8 {
    margin-top: 2rem !important;
  }

  /* .aboutuspage-image2 {
    position: absolute;
    left: 18rem !important;
    top: 6.5rem !important;
  } */

  .aboutuspage-image-position1 {
    margin-top: 0rem !important;
  }

  .aboutuspage-image-position2 {
    margin-top: 0rem !important;
    position: relative;
  }

  .aboutuspage-image-position3 {
    margin-top: 0rem !important;
  }

  .p-5 {
    padding: 0rem !important;
  }
  .Group {
    padding-top: 19px;
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: #131f4c;
  }
  .donec {
    padding-right: 30px;
  }

  .stratergy1 {
    padding-top: 30px;
  }
  .py-5 {
    padding-top: 1rem !important;
    padding-bottom: 6rem !important;
  }
  .align-tasks-with-strategic-goals-MH5 {
    font-family: Libre Baskerville, "Source Sans Pro";
    font-size: 1.4rem;
    font-weight: 700;
  }
  .m-5 {
    margin: 1.5rem !important;
  }
  .try-button1 {
    padding: 3px 5px;
    font-size: 1.4vh;
  }
  .try-button {
    height: 60%;
    font-size: 1.1vh;
  }

  .plansom-logo {
    height: 2rem;
    margin-right: 6%;
    object-fit: contain;
    vertical-align: top;
    width: 13vh;
    display: flex;
  }
  .header-item {
    align-items: center;
    column-gap: calc(100% - 85%);
    display: flex;
    padding-top: 10px;
    margin-right: 38%;
  }
  .items {
    color: #131f4c;
    flex-shrink: 0;
    font-family: Open Sans, "Source Sans Pro";
    /* font-size: 1.7vh; */
    font-weight: 400;
    line-height: 1.2000000477;
    white-space: nowrap;
  }
  .free-trail {
    font-size: 1.1rem;
  }
  .headers {
    padding: 2% 0%;
    display: inline-block;
  }
  .header1 {
    display: flex;
    align-items: center;
    flex-shrink: inherit;
    width: 100%;
  }
  .quote1 {
    margin-bottom: 2vh;
  }
  .quote {
    /* padding-left: 5vw;
    width: 50vw;
    padding-top: 7rem; */
  }
  .quote1-sub-0 {
    font-size: 3.5vh;
  }
  .quote1-sub-1 {
    font-size: 2.7vh;
  }
  .Group-2 {
    height: 30vh;
  }
  .quote-text {
    font-size: 0.8rem;
    white-space: pre-wrap;
  }

  .aboutuspage-quote-font {
    font-size: 1.25rem;
    white-space: pre-wrap;
  }

  .group5 {
    height: 25vh;
    width: 50vw;
  }
  .ps-5 {
    padding-left: 0rem !important;
  }
  .stop-useless {
    font-size: 1.1vmax;
    white-space: pre-wrap;
  }
  .focus {
    font-size: 1.9vmax;
    white-space: pre-wrap;
  }
  .stratergy {
    padding: 1rem 0rem 0rem 0rem;
  }
  .group3 {
    height: 20vh;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1283px) {
  .textHeadlineheight{
    margin-bottom: 48px;
  }
  .textsizeline{
    margin-bottom: 20px;
  }
  .textForSize{
    margin-bottom: 46px;
  }

  .header-buttonforSmall {
    padding-top: 0px !important;
  }

  .plansompage-image1 {
    margin-top: 5rem !important;
  }

  .plansompage-image2 {
    margin-top: 8.5rem !important;
  }

  .plansompage-image3 {
    margin-top: 8rem !important;
  }

  .plansompage-image4 {
    margin-top: 2rem !important;
  }

  .plansompage-image5 {
    margin-top: 2rem !important;
  }

  .plansompage-image6 {
    margin-top: 4rem !important;
  }

  .plansompage-image7 {
    margin-top: 2rem !important;
  }

  .plansompage-image8 {
    margin-top: 2rem !important;
  }

  /* .aboutuspage-image2 {
    position: absolute;
    left: 3rem !important;
    top: 6.5rem !important;
  } */

  .aboutuspage-image-position1 {
    margin-top: 15rem !important;
  }

  .aboutuspage-image-position2 {
    margin-top: 3rem !important;
    position: relative;
  }

  .aboutuspage-image-position3 {
    margin-top: 15rem !important;
  }

  .image-size {
    height: 30vh;
    width: 43vw;
  }

  .col-width {
    width: 5vw;
  }

  .section-heading {
    font-size: 2.9vh;
    font-weight: 600;
  }
  .section-title {
    color: rgb(53, 111, 212);
    font-size: 1.5vw;
  }
  .section-info {
    font-size: 1.4vw;
  }
  .headers {
    padding: 2% 0%;
    display: inline-block;
  }
  .donec {
    padding-right: 80px;
  }
  .Group {
    padding-top: 20px;
  }
  .try-button1 {
    padding: 5px 7px;
    font-size: 2vh;
  }
  .align-tasks-with-strategic-goals-MH5 {
    font-family: Libre Baskerville, "Source Sans Pro";
    font-size: 2rem;
    font-weight: 700;
  }
  .header-item {
    align-items: center;
    column-gap: calc(100% - 85%);
    display: flex;
    margin-right: 38%;
  }
  .header1 {
    display: flex;
    align-items: center;
    flex-shrink: inherit;
    width: 100%;
  }
  .try-button {
    height: 80%;
    font-size: 1.5vh;
  }
  .plansom-logo {
    margin-right: 6%;
    width: 10.5rem;
  }
  .quote {
    /* padding-left: 5vw;
    padding-top: 7rem; */
  }
  .free-trail {
    font-size: 1.3rem;
  }
  .quote1 {
    margin-bottom: 2vh;
  }
  .quote1-sub-0 {
    font-size: 4.2vw;
  }
  .quote1-sub-1 {
    font-size: 3vw;
  }
  .Group-2 {
    height: 35vh;
  }
  /* .quote-text {
    font-size: 1.9vh;
    white-space: pre-wrap;
  } */
  .quote-text {
    font-size: 1.25rem;
    white-space: pre-wrap;
  }

  .aboutuspage-quote-font {
    font-size: 1.25rem;
    white-space: pre-wrap;
  }

  .stratergy {
    padding: 1rem 0rem 0rem 5rem;
  }
  .focus {
    white-space: pre-wrap;
  }
  .group5 {
    height: 30vh;
    width: 50vw;
  }
  .m-5 {
    margin: 2rem !important;
  }
  .p-5 {
    padding: 0rem !important;
  }
  .group3 {
    height: 22vh;
    padding-left: 50px;
  }
}

@media screen and (min-width: 1284px) and (max-width: 1440px) {

  .textForSize{
    margin-bottom: 24px !important;
  }

  .header-buttonforSmall {
    padding-top: 0px !important;
  }

  .plansompage-image1 {
    margin-top: 6rem !important;
  }

  .plansompage-image2 {
    margin-top: 3.5rem !important;
  }

  .plansompage-image3 {
    margin-top: 3rem !important;
  }

  .plansompage-image4 {
    margin-top: 0rem !important;
  }

  .plansompage-image6 {
    margin-top: 1rem !important;
  }

  .plansompage-image8 {
    margin-top: 1rem !important;
  }
  /* .aboutuspage-image2 {
    position: absolute;
    left: 15rem !important;
    top: 6.5rem !important;
  } */

  .aboutuspage-image-position1 {
    margin-top: 13rem !important;
  }

  .aboutuspage-image-position2 {
    margin-top: 3rem !important;
    position: relative;
  }

  .aboutuspage-image-position3 {
    margin-top: 13rem !important;
  }

  .image-size {
    height: 32vh;
    width: 42vw;
  }
  .col-width {
    width: 6vw;
  }
  .section-heading {
    font-size: 2.5vw;
    font-weight: 600;
  }
  .section-title {
    color: rgb(53, 111, 212);
    font-size: 1.1vw;
  }

  .section-info {
    font-size: 1.3vw;
  }
  .headers {
    padding: 2% 0%;
    display: inline-block;
  }
  .header-item {
    align-items: center;
    column-gap: calc(100% - 80%);
    display: flex;
    margin-right: 45%;
    margin-left: 5%;
  }
  .donec {
    padding-right: 80px;
  }
  .lorem {
    padding-right: 10px;
  }
  .items {
    padding-top: 10px;
    /* font-size: 1.8vh; */
  }
  .plansom-logo {
    width: 12rem;
    margin-right: 6%;
  }
  .group3 {
    height: 24vh;
    width: 100%;
    /* margin-top: 5vh; */
    padding-left: 30px;
  }
  .header1 {
    display: flex;
    align-items: center;
    flex-shrink: inherit;
    width: 100%;
  }
  .try-button {
    font-size: 2.1vh;
  }
  .quote1-sub-0 {
    font-size: 4.2vw;
  }
  .quote1-sub-1 {
    font-size: 3vw;
  }
  .Group-2 {
    height: 45vh;
  }
  /* .quote-text {
    white-space: pre-wrap;
  } */
  .quote-text {
    font-size: 1.45rem;
    white-space: pre-wrap;
  }

  .aboutuspage-quote-font {
    font-size: 1.45rem;
    white-space: pre-wrap;
  }

  .quote {
    /* padding-left: 7.5vw;
    padding-top: 6rem; */
  }
  .group5 {
    height: 40vh;
    width: 50vw;
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #131f4c;
  }
  .free-trail {
    font-size: 1.3rem;
  }
  .align-tasks-with-strategic-goals-MH5 {
    font-size: 40px;
    font-weight: 600;
  }
  .m-5 {
    margin: 2rem !important;
  }
  .Group {
    padding-top: 20px;
  }
  .try-button1 {
    padding: 7px 9px;
  }
  .header-buttons {
    align-items: center;
    display: flex;
    column-gap: calc(100% - 70%);
  }
  .stratergy {
    padding: 9rem 0rem 0rem 5rem;
  }
}

@media screen and (min-width: 1441px) and (max-width: 2560px) {
  .textForSize{
    margin-bottom: 25px;
  }
  .header-buttonforSmall {
    padding-top: 0px !important;
  }
 
  .fs-18{
    font-size: 1.825rem !important;
  }

  .plansompage-image2 {
    margin-top: 2rem !important;
  }

  /* .aboutuspage-image2 {
    position: absolute;
    left: 18rem !important;
    top: 6.5rem !important;
  } */

  .aboutuspage-image-position2 {
    margin-top: 1rem !important;
    position: relative;
  }

  .aboutuspage-image-position3 {
    margin-top: 10rem !important;
  }

  .image-size {
    height: 36vh;
    width: 40vw;
  }
  .col-width {
    width: 6vw;
  }
  .section-heading {
    font-size: 2.3vw;
    font-weight: 600;
  }
  .section-title {
    color: rgb(53, 111, 212);
    font-size: 1.1vw;
  }

  .section-info {
    font-size: 1.2vw;
  }
  .headers {
    padding: 2% 0%;
    display: inline-block;
  }
  .header1 {
    display: flex;
    align-items: center;
    flex-shrink: inherit;
    width: 100%;
  }
  .header-item {
    align-items: center;
    column-gap: calc(100% - 80%);
    display: flex;
    margin-right: 54%;
  }
  .try-button {
    font-size: 2.1vh;
  }
  .items {
    color: #131f4c;
    flex-shrink: 0;
    font-family: Open Sans, "Source Sans Pro";
    /* font-size: 2vh; */
    font-weight: 400;
    padding-top: 5px;
    line-height: 1.2000000477;
    white-space: nowrap;
  }
  .quote {
    padding-left: 8vw;
    padding-right: 40px;
    padding-top: 8rem;
  }
  .group5 {
    height: 111%;
    width: 50vw;
  }
  .plansom-logo {
    height: 3rem;
    margin-right: 6%;
  }
  .stratergy1 {
    margin-top: 200px;
    margin-left: 70px;
  }
  .free-trail {
    font-size: 1.6rem;
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.1vw;
    line-height: 140%;
    color: #131f4c;
  }
  .Group-2 {
    height: 50vh;
  }
  .group3 {
    height: 27vh;
    width: 93%;
  }
  .m-5 {
    margin: 2rem !important;
  }
  .align-tasks-with-strategic-goals-MH5 {
    font-family: Libre Baskerville, "Source Sans Pro";
    font-size: 3.1rem;
    font-weight: 700;
  }
  /* .quote-text {
    font-size: 2.2vh;
    white-space: pre-wrap;
  } */
  .quote-text {
    font-size: 1.3rem;
    white-space: pre-wrap;
  }

  /* .btn{
    font-size: 18px !important;
    height: 36px !important;
  } */

  .aboutuspage-quote-font {
    font-size: 1.25rem;
    white-space: pre-wrap;
  }

  .Group {
    padding-top: 45px;
  }
  .lorem {
    font-size: 22px;
    padding-right: 45px;
  }
  .quote1 {
    margin-bottom: 4vh;
  }
  .donec {
    font-size: 22px;
    padding-right: 110px;
  }
  .p-5 {
    padding: 0rem !important;
  }
  .stratergy {
    padding: 10rem 0rem 0rem 5rem;
  }
  .quote1-sub-0 {
    font-size: 4.2vw;
  }
  .quote1-sub-1 {
    font-size: 3vw;
  }
}

@media screen and (min-width: 2561px) and (max-width: 3100px) {
  .quote-text {
    font-size: 2.25rem !important;
    white-space: pre-wrap;
  }

  .aboutuspage-quote-font {
    font-size: 1.25rem;
    white-space: pre-wrap;
  }

  .hitext {
    font-size: 28px !important;
  }
  /* 
  .aboutuspage-image2 {
    position: absolute;
    left: 39.5rem !important;
    top: 3.5rem !important;
  } */
  .header1 {
    display: flex;
    align-items: center;
    flex-shrink: inherit;
    width: 100%;
}
}

@media screen and (min-width: 2561px) and (max-width: 3845px) {
  .plansompage-image2 {
    margin-top: 1.5rem !important;
  }

  .aboutuspage-image-position1 {
    margin-top: 6rem !important;
  }

  .aboutuspage-image-position2 {
    margin-top: 0rem !important;
    position: relative;
  }

  .aboutuspage-image-position3 {
    margin-top: 5rem !important;
  }

  .image-size {
    height: 32vh;
    width: 40vw;
  }
  .col-width {
    width: 7vw;
  }
  .section-heading {
    font-size: 2vw;
    font-weight: 600;
  }
  .section-title {
    color: rgb(53, 111, 212);
    font-size: 1vw;
  }

  .section-info {
    font-size: 1.2vw;
  }

  .quote1-sub-0 {
    font-size: 4.2vw;
  }
  .quote1-sub-1 {
    font-size: 3vw;
  }
  .headers {
    padding: 2% 0%;
    display: inline-block;
  }
  .header-item {
    margin-right: 48%;
  }
  .quote {
    padding-top: 14rem;
  }
  .quote1 {
    padding-right: 200px;
  }
  /* .quote-text {
    font-size: 3.25rem !important;
    white-space: pre-wrap;
  } */
  p {
    font-size: 46px;
  }
  .free-trail {
    font-size: 2.2rem;
  }
  .stratergy {
    padding: 14rem 0rem 0rem 10rem;
    padding-left: 250px;
  }
  .Group {
    padding-top: 4vh;
    padding-left: 9rem;
  }
  .align-tasks-with-strategic-goals-MH5 {
    font-family: Libre Baskerville, "Source Sans Pro";
    font-size: 110px;
    font-weight: 700;
    white-space: pre-wrap;
  }
  .group3 {
    height: 37vh;
    padding-left: 90px;
  }
  .lorem {
    padding-right: 100px;
  }
  .donec {
    padding-right: 250px;
  }
  .group5 {
    padding-right: 150px;
    padding-top: 100px;
    height: 60vh;
  }
  .header1 {
    display: flex;
    align-items: center;
    flex-shrink: inherit;
    width: 100%;
}
}

@media screen and (min-width: 3101px) and (max-width: 3845px) {

  .quote1-sub-1 {
    font-size: 3vw;
  }

  .quote-text {
    font-size: 3.25rem !important;
    white-space: pre-wrap;
  }

  .aboutuspage-quote-font {
    font-size: 2.25rem;
    white-space: pre-wrap;
  }

  .hitext {
    font-size: 40px !important;
  }

  /* .aboutuspage-image2 {
    position: absolute !important;
    left: 58rem !important;
    top: 0.5rem !important;
  } */

  .aboutuspage-image {
    width: 40.838rem !important;
    height: 35.188rem !important;
    flex-shrink: 0;
  }
  /* 
  .aboutuspagesvg {
    width: 318px !important;
    height: 319px !important;
  } */
  .header1 {
    display: flex;
    align-items: center;
    flex-shrink: inherit;
    width: 100%;
}
}

.abouttitle {
  font-family: Libre Baskerville;
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 58px; /* 120.833% */
}

.aboutus-text {
  color: rgba(0, 0, 0, 0.8);
  font-family: Open Sans;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 32px */
}

.aboutuspage-image {
  width: 25.838rem;
  height: 17.188rem;
  flex-shrink: 0;
}

.About-us-title {
  color: #131f4c;
  font-family: Libre Baskerville;
  font-size: 1.9rem;
  font-style: normal;
  font-weight: 700;
  line-height: 108%; /* 34.56px */
}
.About-us-text {
  color: rgba(0, 0, 0, 0.8);
  font-family: Open Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.list-with-dots li::before {
  content: "\2022"; /* Unicode character for a bullet (dot) */
  color: rgba(0, 0, 0, 0.8);
  display: inline-block;
  width: 1rem;
  margin-left: -1em;
}

.chatbot-container {
  max-width: 400px;
  margin: auto;
  /* border: 1px solid #ccc; */
  /* border-radius: 10px; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
}

.chatbot-header {
  background-color: "#ffffff";
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.chatbot-messages {
  display: flex;
  flex-direction: column;
  padding: 15px;
  /* max-height: 300px; */
  overflow-y: auto;
  background-color: #131f4c;
}

.user-message {
  align-self: flex-end;
  background-color: #356fd4;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 20px 0px 20px 20px;
  max-width: 70%;
  word-wrap: break-word;
  margin-left: 10px;
  margin-right: 20px;
}

.bot-message {
  align-self: flex-start;
  background-color: #e7e9ed;
  color: #000000;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 0px 20px 20px 20px;
  max-width: 70%;
  word-wrap: break-word;
  margin-left: 10px;
}

.chatbotalert-box {
  background-color: #a8d6c5; /* Background color */
  padding: 10px; /* Padding */
  border: 1px solid #ddd; /* Border */
  margin-bottom: 10px; /* Margin at the bottom of each box */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift effect */
}

.chatbot-input input {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 10px;
  font-size: 1em;
}

.chatbot-input input:focus {
  outline: none;
}

.chatbot-container {
  /* height: 0;
  opacity: 0; */

  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.chatbot-container.open {
  height: 60%;
  opacity: 1;
}

.chatbot-container.close {
  height: 0;
  opacity: 0;
  transition-delay: 1.5s;
}

/* .aboutuspage-image-position1 {
  margin-top: 11rem !important;
} */

@media screen and (min-width:400px) and (max-width: 444px) {
.objective-title{
  margin-right: 35% !important;
}
}

@media screen and (min-width:320px) and (max-width: 342px) {
  .objective-title{
    margin-right: 15% !important;
  }
  }

  @media screen and (min-width:343px) and (max-width: 381px) {
    .objective-title{
      margin-right: 20% !important;
    }
    }  

    @media screen and (min-width:382px) and (max-width: 399px) {
      .objective-title{
        margin-right: 25% !important;
      }
      }  

