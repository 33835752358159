//heart Blast Effect

.heart-blast {
    background-position: -1680px 0!important;
    transition: background 1s steps(28);
}

.heart {
    width   	: 60px;
    height  	: 60px;
    display 	: inline-block;
    background  : url('../images/like.png');
    cursor      : pointer;
    margin      : -25px -15px;
}

.header-profile{
	position:relative;
	margin-left: 1.875rem;
	padding-left: 1.875rem;
	.nav-link{
		position:relative;
		border-radius: 3rem;
		padding:0.1875rem;
		img{
			height:3.75rem;
			width:3.75rem;
			border-radius:4.25rem;
			@include respond('laptop'){
				height: 2.1875rem;
				width: 2.1875rem;	
			}
		}
	}
}
.plus-box {
	padding: 1.125rem 1.1875rem 1.125rem;
    position: relative;
    overflow: hidden;
    margin: 0rem 2.5rem;
    border-radius: 1rem;
    align-items: center;
    margin-bottom: 1.5625rem;
    margin-top: 1.625rem;
    background-image: url(../images/circles.png);
    background-repeat: no-repeat;
    background-position: right top;
	box-shadow:0 0.25rem rgba(0, 0, 0, 0.04);
	@include respond ('laptop'){
		margin: 1.6rem 1.6rem;
	}
}	

.header-profile{
	.nav-link{
		padding-right: 0!important;
		padding-left: 0!important;	
	}	
}

.ck.ck-editor__editable_inline>:first-child{
	height:9.375rem;	
}
.textarea_editor {
	height:7.5rem;	
}
.current-icon{
	a{
		height: 3.25rem;
		width: 3.25rem;
		background:var(--primary);
		display: block;
		border-radius: 3.125rem;
		line-height: 3.25rem;
		text-align: center;
		color: white;
		font-size: 1.5625rem;
		transform: rotate(45deg);		
	}
}
.current-tab{
	margin-bottom:2rem;
	.nav-tabs{
		border:0;
		.nav-item{
			.nav-link{
				border:0;
				border-radius:3.125rem;
				padding: 0.4rem 1rem;
				font-size:1rem;
				font-weight:400;
				color:#717579;
				&.active{
					background:#EEEEEE;	
				}
			}	
		}
	}	
}
.bg-progradient{
	background-image: linear-gradient(to right,var(--primary) 0,var(--primary-dark) 51%,var(--primary) 87%);
}
.manage-project{
	position:relative;
	overflow: hidden;
	&:before{
		position:absolute;
		content:"";
		top:0;
		background-image: url(../images/ellipse.png);
		height: 100%;
		width: 100%;
		background-repeat: no-repeat;
		left: 1.25rem;
	}
	&:after{
		position:absolute;
		content:"";
		background-image: url(../images/ellipse2.png);
		height: 100%;
		width: 100%;
		background-repeat: no-repeat;
		background-position: 71% 130%;
	}
	a,h4,span{
		position:relative;
		z-index:1;
	}
}
.donut-chart-sale small, .donut-chart-sale .small {
	font-size: 1rem;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    align-items: center;
    top: 0;
    justify-content: center;
    font-weight: 600;
	
}
.default-progress{
	height:0.625rem;	
}
.bg-ombe{
	background:#9D8160;	
}
.bg-pushup{
	background:#ED368E;	
}
.bg-kleon{
	background:#833AE0;	
}
.bg-biji{
	background:#2228B4;	
}
.slide-icon{
	span{
		height:4.25rem;
		width:4.25rem;
		border-radius:4.25rem;
		line-height:4.25rem;
		text-align:center;
		display:block;
		@include respond ('laptop'){
			height:3.25rem;
			width:3.25rem;
			line-height:3.25rem;
			svg{
				width: 16px;
					
				
			}
		}
	}	
}
.bgl-blue{
	background:rgba($blue,0.1);	
}
.slider-button{
	span{
		padding:0.625rem 1.25rem;
		border-radius:0.25rem;
	}	
}
.slide-info{
	padding: 0.9375rem 1.5625rem;
    border: 0.0625rem solid #EEEEEE;
    border-radius: 0.75rem;	
	margin-right: 0.4rem;
}
.msg-bx{
	border-bottom:0.0625rem solid var(--rgba-primary-1);	
	padding: 1rem 1.9rem;
	position:relative;
	.msg{
		.image-box{
			height: 3.125rem;
			width: 3.125rem;	
			position:relative;
			img{
				height:100%;
				width:100%;
				border-radius:3.125rem;
				object-fit:cover;
			}
			&.active{
				&:after{
					height:1rem;
					width:1rem;
					border-radius:3.125rem;
					background:$success;
					border:0.0625rem solid $white;
					bottom: 0;
					right: -0.3125rem;
					position:absolute;
					content:"";
				}	
			}

		}
		
	}
	
}
//Custom

.image-placeholder {
    position: relative;
    max-width: 130px;
    margin: 10px auto;
    .avatar-edit {
        position: absolute;
        right: 12px;
        z-index: 1;
        top: 0px;
        input {
            display: none;
            + label {
                display: inline-block;
                width: 34px;
                height: 34px;
                margin-bottom: 0;
                border-radius: 100%;
                background: #FFFFFF;
                border: 1px solid transparent;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;
                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }
                &:after {
                    content: "\f303";
                    font-family: 'Font Awesome 5 Free';
					font-weight: 600;
                    color: #757575;
                    position: absolute;
                    top:6px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }
    .avatar-preview {
        width: 120px;
        height: 120px;
        position: relative;
        border-radius: 100%;
        border: 3px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        & > div {
            width: 100%;
			height: 100%;
            border-radius: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
			overflow: hidden;
			img{
				height: 100%;
				width: 100%;
				object-fit: cover
			}
        }
    }
}
.project-boxed {
    overflow: hidden;
}

.btn-blog{
	padding-left: 18px;
    padding-right: 18px;
	height: 42px;
    line-height: 40px;
}



.MuiPickersToolbar-toolbar,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint,
.MuiPickerDTTabs-tabs,
.MuiPickersDay-daySelected,
.MuiPickersDay-daySelected:hover{
    background-color: $primary !important;
}
.MuiPickersClockPointer-thumb{
    border: 14px solid $primary !important;    
}
.MuiButton-textPrimary{
    color: $primary !important;    
}
.MuiInput-underline::after{
    border-bottom: 2px solid $primary !important;    
}


.todo-button{
	.btn{
		&.btn-xs{
			line-height: 17px;
		}
	}
}
.table-customer{
	tr{
		th,
		td{
			&:first-child{
				width: 80px;
			}
		}
	}
}